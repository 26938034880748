import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Collapse,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import sidebarStore from "../store/sidebarStore";

const Sidebar = ({ startHeight }) => {
  const navigate = useNavigate();

  const hoverColor = "#E7E7E7";
  const selectedColor = "#DCDCDC";
  const drawerWidthCollapsed = 58;
  const drawerWidthExpanded = 210;
  const itemHeight = 48;

  useEffect(() => {
    if (sidebarStore.hover) {
      const parentIndex = findParentIndex(sidebarStore.selectedIndex);
      if (parentIndex !== null) {
        sidebarStore.setOpenIndex(parentIndex);
      }
    }
    // eslint-disable-next-line
  }, [sidebarStore.selectedIndex, sidebarStore.hover]);

  const handleClick = (index) => {
    sidebarStore.setOpenIndex(sidebarStore.openIndex === index ? null : index);
  };

  const handleItemClick = (text, link) => {
    sidebarStore.setSelectedIndex(text);
    if (link) {
      setTimeout(() => {
        navigate(link);
      }, 300);
    }
  };

  const handleMouseEnter = () => {
    sidebarStore.setHover(true);
  };

  const handleMouseLeave = () => {
    sidebarStore.setHover(false);
    sidebarStore.setOpenIndex(null);
  };

  const findParentIndex = (text) => {
    for (let i = 0; i < sidebarStore.items.length; i++) {
      const index = sidebarStore.items[i].findIndex((item) =>
        item.subItems?.some((subItem) => subItem.text === text)
      );
      if (index !== -1)
        return index + sidebarStore.items.slice(0, i).flat().length;
    }
    return null;
  };

  const renderSubItems = (subItems) => {
    return subItems.map((subItem) => (
      <ListItem
        button
        key={subItem.text}
        sx={{
          pl: 2,
          backgroundColor:
            sidebarStore.selectedIndex === subItem.text
              ? selectedColor
              : "transparent",
          "&:hover": {
            backgroundColor:
              sidebarStore.selectedIndex === subItem.text
                ? selectedColor
                : hoverColor,
          },
          height: itemHeight,
        }}
        onClick={() => handleItemClick(subItem.text, subItem.link)}
      >
        <ListItemIcon />
        <ListItemText
          primary={<Typography variant="body2">{subItem.text}</Typography>}
        />
      </ListItem>
    ));
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        "& .MuiDrawer-paper": {
          top: startHeight,
          height: `calc(100% - ${startHeight})`,
          width: sidebarStore.hover
            ? drawerWidthExpanded
            : drawerWidthCollapsed,
          bgcolor: "#F2F2F2",
          overflow: "hidden",
          whiteSpace: "nowrap",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <List sx={{ paddingTop: 0 }}>
        {sidebarStore.items.map((itemGroup, groupIndex) => (
          <React.Fragment key={groupIndex}>
            {itemGroup.map((item, itemIndex) => {
              const isParentHighlighted =
                !sidebarStore.hover &&
                item.subItems &&
                findParentIndex(sidebarStore.selectedIndex) === itemIndex;
              const isItemHighlighted =
                sidebarStore.hover &&
                !item.subItems &&
                sidebarStore.selectedIndex === item.text;

              return (
                <React.Fragment key={item.text}>
                  <ListItem
                    button
                    onClick={() =>
                      item.subItems
                        ? handleClick(itemIndex)
                        : handleItemClick(item.text, item.link)
                    }
                    sx={{
                      backgroundColor: sidebarStore.hover
                        ? isItemHighlighted
                          ? selectedColor
                          : "transparent"
                        : isParentHighlighted ||
                          (!item.subItems &&
                            sidebarStore.selectedIndex === item.text)
                        ? selectedColor
                        : "transparent",
                      "&:hover": {
                        backgroundColor: sidebarStore.hover
                          ? isItemHighlighted
                            ? selectedColor
                            : hoverColor
                          : "transparent",
                      },
                      height: itemHeight,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    {sidebarStore.hover && (
                      <ListItemText
                        primary={
                          <Typography variant="body2">{item.text}</Typography>
                        }
                      />
                    )}
                    {item.subItems ? (
                      sidebarStore.openIndex === itemIndex ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : null}
                  </ListItem>
                  <Collapse
                    in={sidebarStore.openIndex === itemIndex}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.subItems && renderSubItems(item.subItems)}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            })}
            {groupIndex < sidebarStore.items.length - 1 && (
              <Divider variant="middle" />
            )}
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
};

export default observer(Sidebar);
