import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import getPagesConfig from "./components/PagesConfig.js";
import PageWrapper from "./components/PageWrapper.js";

import { Amplify } from "aws-amplify";
import { ThemeProvider } from "@aws-amplify/ui-react";
import config from "./awsExports";

import "./index.css";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure(config);

const index = async () => {
  const pageConfigs = await getPagesConfig();

  let globalKey = 0;
  const newKey = () => {
    globalKey += 1;
    return globalKey;
  };

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          {pageConfigs.map((route) => {
            const {
              path,
              element,
              showNavbar,
              showSidebar,
              title,
              authRequired,
            } = route;
            return (
              <Route
                key={newKey()}
                path={path}
                element={
                  <PageWrapper
                    element={element}
                    title={title}
                    showNavbar={showNavbar}
                    showSidebar={showSidebar}
                    authRequired={authRequired}
                  />
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

index();
