import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { withAuthenticator } from "@aws-amplify/ui-react";

const Navbar = ({ title, signOut, user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    handleClose();
    signOut();
    navigate("/");
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        height: 66,
        bgcolor: "#FEFEFE",
        boxShadow: "none",
        borderBottom: "2px solid #D9D9D9",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Link to="#">
          <img
            src="/adminiLogo.svg"
            alt="Logo"
            style={{ width: "auto", height: "100%" }}
          />
        </Link>

        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <Typography
            variant="h5"
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
              fontSize: "1.5rem",
              color: "black",
              ml: 12,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton sx={{ mr: 2, color: "black" }}>
            <NotificationsNoneOutlinedIcon fontSize="medium" />
          </IconButton>
          <IconButton sx={{ mr: 2, color: "black" }}>
            <SettingsOutlinedIcon fontSize="medium" />
          </IconButton>
          <IconButton sx={{ color: "black" }} onClick={handleProfileClick}>
            <Avatar src={user?.picture || ""} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem disabled>
              {user?.signInDetails.loginId || "Guest User"}
            </MenuItem>
            <Divider variant="middle" />
            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default withAuthenticator(Navbar, { signOut: true });
