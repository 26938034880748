const getPagesConfig = async () => {
  const context = require.context("../Pages", true, /pageConfig\.jsx$/);

  const pageConfigs = context.keys().map((key) => {
    const module = context(key);
    return module.default || module;
  });

  return pageConfigs;
};

export default getPagesConfig;
