import React from 'react';
import Contacts from './Contacts';

// eslint-disable-next-line
export default {
  path: '/contacts',
  title: 'Contacts',
  element: <Contacts />,
  showNavbar: true,
  showSidebar: true,
  authRequired: true,
};
