import React from "react";
import {
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Slider,
  FormGroup,
  Typography,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { observer } from "mobx-react-lite";
import databaseStore from "../store/databaseStore";

const SearchSettings = observer(() => {
  const handleDateChange = (newValue, isStartDate) => {
    if (isStartDate) {
      databaseStore.setStartDate(newValue);
      if (newValue > databaseStore.selectedDateRange.endDate) {
        databaseStore.setEndDate(null);
      }
    } else {
      if (newValue < databaseStore.selectedDateRange.startDate) {
        databaseStore.setStartDate(null);
      }
      databaseStore.setEndDate(newValue);
    }
  };

  return (
    <Box sx={{ margin: 2 }}>
      {/* Main Title */}
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Search Settings
      </Typography>

      {/* Region */}
      <Typography component="legend" sx={{ fontSize: 18, marginTop: 2 }}>
        Region
      </Typography>
      <Box>
        <Autocomplete
          size="small"
          multiple
          freeSolo
          options={databaseStore.availableRegions}
          defaultValue={["Any"]}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} />}
          onChange={(event, newValue) => {
            databaseStore.setRegions(newValue);
          }}
          sx={{ backgroundColor: "#FEFEFE" }}
        />
      </Box>

      {/* Date Range */}
      <Typography component="legend" sx={{ fontSize: 18, marginTop: 2 }}>
        Date Range
      </Typography>
      <Box>
        <RadioGroup row defaultValue="postedAt">
          <FormControlLabel
            value="postedAt"
            control={<Radio />}
            label={<Typography sx={{ fontSize: 14 }}>Posted At</Typography>}
          />
          <FormControlLabel
            value="modifiedAt"
            control={<Radio />}
            label={<Typography sx={{ fontSize: 14 }}>Modified At</Typography>}
          />
          <FormControlLabel
            value="respondBy"
            control={<Radio />}
            label={<Typography sx={{ fontSize: 14 }}>Respond By</Typography>}
          />
        </RadioGroup>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <DatePicker
              label="Start Date"
              value={databaseStore.selectedDateRange.startDate}
              onChange={(date) => handleDateChange(date, true)}
              textField={(params) => <TextField {...params} />}
              sx={{ backgroundColor: "#FEFEFE" }}
            />
            <DatePicker
              label="End Date"
              value={databaseStore.selectedDateRange.endDate}
              onChange={(date) => handleDateChange(date, false)}
              textField={(params) => <TextField {...params} />}
              sx={{ backgroundColor: "#FEFEFE" }}
            />
          </Box>
        </LocalizationProvider>
      </Box>

      {/* Status */}
      <Typography component="legend" sx={{ fontSize: 18, marginTop: 2 }}>
        Status
      </Typography>
      <Box>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={databaseStore.statuses.open}
                onChange={() => databaseStore.toggleStatus("open")}
                sx={{
                  color: "green",
                  "&.Mui-checked": {
                    color: "green",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: 14,
                  backgroundColor: "green",
                  padding: "0px 4px",
                  borderRadius: 1,
                  color: "white",
                }}
              >
                Open
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={databaseStore.statuses.cancelled}
                onChange={() => databaseStore.toggleStatus("cancelled")}
                sx={{
                  color: "red",
                  "&.Mui-checked": {
                    color: "red",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: 14,
                  backgroundColor: "red",
                  padding: "0px 4px",
                  borderRadius: 1,
                  color: "white",
                }}
              >
                Cancelled
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={databaseStore.statuses.expired}
                onChange={() => databaseStore.toggleStatus("expired")}
                sx={{
                  color: "grey",
                  "&.Mui-checked": {
                    color: "grey",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: 14,
                  backgroundColor: "grey",
                  padding: "0px 4px",
                  borderRadius: 1,
                  color: "white",
                }}
              >
                Expired
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={databaseStore.statuses.awarded}
                onChange={() => databaseStore.toggleStatus("awarded")}
                sx={{
                  color: "blue",
                  "&.Mui-checked": {
                    color: "blue",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: 14,
                  backgroundColor: "blue",
                  padding: "0px 4px",
                  borderRadius: 1,
                  color: "white",
                }}
              >
                Awarded
              </Typography>
            }
          />
        </FormGroup>
      </Box>

      {/* Category */}
      <Typography component="legend" sx={{ fontSize: 18, marginTop: 2 }}>
        Category
      </Typography>
      <Box>
        <Autocomplete
          size="small"
          multiple
          freeSolo
          options={databaseStore.availableCategories}
          defaultValue={["Any"]}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} />}
          onChange={(event, newValue) => {
            databaseStore.setCategories(newValue);
          }}
          sx={{ backgroundColor: "#FEFEFE" }}
        />
      </Box>

      {/* Value */}
      <Typography component="legend" sx={{ fontSize: 18, marginTop: 2 }}>
        Value
      </Typography>
      <Box>
        <Typography>
          {databaseStore.formatValue(
            databaseStore.value[0],
            databaseStore.value[1]
          )}
        </Typography>
        <Box sx={{ px: 1 }}>
          <Slider
            value={databaseStore.getSliderValue()}
            onChange={(event, newValue) => databaseStore.setValue(newValue)}
            min={databaseStore.getSliderMin()}
            max={databaseStore.getSliderMax()}
            step={databaseStore.getSliderStep()}
            valueLabelDisplay="off"
          />
        </Box>
      </Box>

      {/* Request Type */}
      <Typography component="legend" sx={{ fontSize: 18, marginTop: 2 }}>
        Request Type
      </Typography>
      <Box>
        <Autocomplete
          size="small"
          multiple
          freeSolo
          options={databaseStore.availableRequestTypes}
          defaultValue={["Any"]}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} />}
          onChange={(event, newValue) => {
            databaseStore.setRequestTypes(newValue);
          }}
          sx={{ backgroundColor: "#FEFEFE" }}
        />
      </Box>
    </Box>
  );
});

export default SearchSettings;
