import React from "react";
import { observer } from "mobx-react";
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import News from "./components/News";
import Bids from "./components/Bids";
import Postings from "./components/Postings";

const Dashboard = observer(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container>
      <Grid container spacing={2} sx={{ marginTop: 0, marginBottom: 2 }}>
        <Grid item xs={12} sm={isMobile ? 12 : 7}>
          <News />
        </Grid>
        <Grid item xs={12} sm={isMobile ? 12 : 5}>
          <Bids />
        </Grid>
        <Grid item xs={12}>
          <Postings />
        </Grid>
      </Grid>
    </Container>
  );
});

export default Dashboard;
