import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import opportunityStore from "../store/opportunityStore";
import { observer } from "mobx-react-lite";

const Dates = observer(() => {
  return (
    <Card>
      <CardContent sx={{ padding: "24px" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "16px" }}
        >
          Dates
        </Typography>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2} alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Respond By
              </Typography>
              <Typography variant="subtitle2">
                {opportunityStore.respondBy}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Last Modified
              </Typography>
              <Typography variant="subtitle2">
                {opportunityStore.lastModified}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});

export default Dates;
