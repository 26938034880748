import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Link,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import dashboardStore from "../store/dashboardStore";

const Bids = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      sx={{
        height: "50vh",
        backgroundColor: "#FEFEFE",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <CardContent
        sx={{
          paddingTop: 2,
          flex: "none",
          borderBottom: "1px solid #ddd",
          backgroundColor: "#fff",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" align="left" sx={{ fontWeight: "bold" }}>
            Recent Bids
          </Typography>
          <Link
            href="#"
            sx={{
              fontSize: "0.875rem",
              fontWeight: "bold",
              textDecoration: "none",
              color: "#1976d2",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            View All
          </Link>
        </div>
      </CardContent>
      <div
        style={{
          flex: 1,
          overflowY: "scroll",
          padding: "8px",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <CardContent
          sx={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          {dashboardStore.bids.map((bid, index) => (
            <Card
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: 2,
                position: "relative",
                padding: 2,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ marginBottom: 1, maxWidth: `calc(100% - 120px)` }}
              >
                <span style={{ fontWeight: "bold" }}>{bid.title}</span>
                {!isMobile && (
                  <Box
                    component="span"
                    sx={{
                      display: "inline-block",
                      marginLeft: 2,
                    }}
                  >
                    <Chip
                      label={dashboardStore.statusMap[bid.status].name}
                      size="small"
                      sx={{
                        backgroundColor:
                          dashboardStore.statusMap[bid.status].color,
                        color: "#fff",
                        fontSize: "0.75rem",
                      }}
                    />
                  </Box>
                )}
                <Box
                  component="span"
                  sx={{
                    position: "absolute",
                    top: 4,
                    right: 4,
                    padding: 1,
                  }}
                >
                  <Typography variant="body2">
                    {dashboardStore.getTimeText(bid.dueDate)}
                  </Typography>
                </Box>
              </Typography>
              {isMobile && (
                <Box
                  component="span"
                  sx={{
                    display: "inline-block",
                    marginBottom: 1,
                  }}
                >
                  <Chip
                    label={dashboardStore.statusMap[bid.status].name}
                    size="small"
                    sx={{
                      backgroundColor:
                        dashboardStore.statusMap[bid.status].color,
                      color: "#fff",
                      fontSize: "0.75rem",
                    }}
                  />
                </Box>
              )}
              <Typography variant="body2">
                {bid.region}&nbsp;│&nbsp;{bid.type}
              </Typography>
              <Typography variant="body2" sx={{ color: "lightgray" }}>
                Contract ID: {bid.id}
              </Typography>
            </Card>
          ))}
        </CardContent>
      </div>
    </Card>
  );
};

export default Bids;
