import React from "react";
import { observer } from "mobx-react";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Container,
  TextField,
  CircularProgress,
} from "@mui/material";
import {
  Share,
  MoreVert,
  AddCircleOutline,
  Search,
  Clear,
} from "@mui/icons-material";
import { useMediaQuery, useTheme } from "@mui/material";
import databaseStore from "../store/databaseStore";

const SearchResults = observer(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchTerm, setSearchTerm] = React.useState(databaseStore.searchTerm);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event, postingId) => {
    databaseStore.setSelectedPostingId(postingId);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    databaseStore.setSelectedPostingId(null);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = () => {
    databaseStore.search(searchTerm); // Use store's search method
  };

  const clearSearch = () => {
    setSearchTerm("");
    databaseStore.setSearchTerm("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit();
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <TextField
        placeholder="Search"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleKeyPress}
        sx={{ mt: 2, backgroundColor: "#FEFEFE" }}
        InputProps={{
          startAdornment: databaseStore.loading ? ( // Use store's loading state
            <IconButton edge="start" aria-label="loading" disabled>
              <CircularProgress size={24} />
            </IconButton>
          ) : (
            <IconButton
              edge="start"
              aria-label="search"
              onClick={handleSearchSubmit}
            >
              <Search />
            </IconButton>
          ),
          endAdornment: searchTerm && (
            <IconButton edge="end" aria-label="clear" onClick={clearSearch}>
              <Clear />
            </IconButton>
          ),
        }}
      />
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
        }}
      >
        <CardContent sx={{ padding: 0 }}>
          {databaseStore.filteredPostings.map((posting, index) => (
            <Card
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: 2,
                position: "relative",
                padding: 2,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton sx={{ padding: 0.5 }} aria-label="add" size="small">
                  <AddCircleOutline fontSize="small" />
                </IconButton>
                <IconButton
                  sx={{ padding: 0.5 }}
                  aria-label="share"
                  size="small"
                >
                  <Share fontSize="small" />
                </IconButton>
                <IconButton
                  sx={{ padding: 0.5 }}
                  aria-label="more"
                  size="small"
                  onClick={(event) => handleMenuOpen(event, posting.id)}
                >
                  <MoreVert fontSize="small" />
                </IconButton>
              </div>
              <Typography
                variant="subtitle1"
                sx={{ marginBottom: 1, maxWidth: `calc(100% - 96px)` }}
              >
                <span style={{ fontWeight: "bold" }}>{posting.title}</span>
                {!isMobile && (
                  <Box
                    component="span"
                    sx={{
                      display: "inline-block",
                      marginLeft: 2,
                    }}
                  >
                    <Chip
                      label={databaseStore.statusMap[posting.status].name}
                      size="small"
                      sx={{
                        backgroundColor:
                          databaseStore.statusMap[posting.status].color,
                        color: "#fff",
                        fontSize: "0.75rem",
                      }}
                    />
                  </Box>
                )}
              </Typography>
              {isMobile && (
                <Box
                  component="span"
                  sx={{ display: "inline-block", marginBottom: 1 }}
                >
                  <Chip
                    label={databaseStore.statusMap[posting.status].name}
                    size="small"
                    sx={{
                      backgroundColor:
                        databaseStore.statusMap[posting.status].color,
                      color: "#fff",
                      fontSize: "0.75rem",
                    }}
                  />
                </Box>
              )}
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                {posting.region}&nbsp;│&nbsp;{posting.type}
              </Typography>
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                {posting.description}
              </Typography>
              <Chip
                label={`Expires on ${databaseStore.getTimeText(
                  posting.dueDate,
                  true
                )}`}
                size="small"
                sx={{
                  backgroundColor: "#D3EED2",
                  color: "#17731B",
                  fontSize: "0.75rem",
                  maxWidth: "fit-content",
                  marginTop: 1,
                }}
              />
              <Typography
                variant="body2"
                sx={{ color: "lightgray", marginTop: 1 }}
              >
                Contract ID: {posting.id}
              </Typography>
            </Card>
          ))}
        </CardContent>
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            if (databaseStore.selectedPostingId) {
              window.location.href = `/opportunities/opportunity/${databaseStore.selectedPostingId}`;
            }
            handleMenuClose();
          }}
        >
          Open
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (databaseStore.selectedPostingId) {
              window.open(
                `/opportunities/opportunity/${databaseStore.selectedPostingId}`,
                "_blank"
              );
            }
            handleMenuClose();
          }}
        >
          Open in a new tab
        </MenuItem>
      </Menu>
    </Container>
  );
});

export default SearchResults;
