import React from "react";

import LandingPage from "./LandingPage.tsx";

// eslint-disable-next-line
export default {
  path: "/",
  title: "",
  element: <LandingPage />,
  showNavbar: false,
  showSidebar: false,
  authRequired: false,
};
