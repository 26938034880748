import React from 'react';
import Partnership from './Partnership';

// eslint-disable-next-line
export default {
  path: '/partnership',
  title: 'Partnership',
  element: <Partnership />,
  showNavbar: true,
  showSidebar: true,
  authRequired: true,
};
