import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Typography,
  Collapse,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import opportunityStore from "../store/opportunityStore";

const Categories = observer(() => {
  const CategoryItem = ({ category }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return (
      <div
        style={{ position: "relative", marginBottom: expanded ? "8px" : "0px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">{`${category.id} — ${category.name}`}</Typography>
          {category.subcategories && category.subcategories.length > 0 ? (
            <IconButton onClick={handleExpandClick} size="small">
              {expanded ? (
                <KeyboardArrowDownIcon fontSize="small" />
              ) : (
                <KeyboardArrowRightIcon fontSize="small" />
              )}
            </IconButton>
          ) : null}
        </div>
        {category.subcategories && category.subcategories.length > 0 && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div
              style={{
                position: "relative",
                marginLeft: 4,
                paddingLeft: 16,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: 1,
                  backgroundColor: "#ddd",
                  height: "100%",
                }}
              />
              <div>
                {category.subcategories.map((sub) => (
                  <CategoryItem key={sub.id} category={sub} />
                ))}
              </div>
            </div>
          </Collapse>
        )}
      </div>
    );
  };

  return (
    <Card elevation={0} sx={{ border: "none" }}>
      <CardContent sx={{ padding: "24px" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "16px" }}
        >
          Categories
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          Admini Categories
        </Typography>
        {opportunityStore.adminiCategories.map((category) => (
          <CategoryItem key={category.id} category={category} />
        ))}
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", marginTop: "16px" }}
        >
          UNSPSC Categories
        </Typography>
        {opportunityStore.unspscCategories.map((category) => (
          <CategoryItem key={category.id} category={category} />
        ))}
      </CardContent>
    </Card>
  );
});

export default Categories;
