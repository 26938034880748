import React from 'react';
import { observer } from 'mobx-react';

const Contacts = observer(() => {
  return (
    <div>
      Contacts Page
    </div>
  );
});

export default Contacts;