import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Link,
  Chip,
  IconButton,
  useMediaQuery,
  useTheme,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import { Share, MoreVert, AddCircleOutline } from "@mui/icons-material";
import dashboardStore from "../store/dashboardStore";

const Postings = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPostingId, setSelectedPostingId] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event, postingId) => {
    setAnchorEl(event.currentTarget);
    setSelectedPostingId(postingId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPostingId(null);
  };

  return (
    <Card
      sx={{
        height: "60vh",
        backgroundColor: "#FEFEFE",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <CardContent
        sx={{
          paddingTop: 2,
          flex: "none",
          borderBottom: "1px solid #ddd",
          backgroundColor: "#fff",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" align="left" sx={{ fontWeight: "bold" }}>
            Latest Postings
          </Typography>
          <Link
            href="/opportunities/database"
            sx={{
              fontSize: "0.875rem",
              fontWeight: "bold",
              textDecoration: "none",
              color: "#1976d2",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            View All
          </Link>
        </div>
      </CardContent>
      <div
        style={{
          flex: 1,
          overflowY: "scroll",
          padding: "8px",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <CardContent
          sx={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          {dashboardStore.postings.map((posting, index) => (
            <Card
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: 2,
                position: "relative",
                padding: 2,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton sx={{ padding: 0.5 }} aria-label="add" size="small">
                  <AddCircleOutline fontSize="small" />
                </IconButton>
                <IconButton
                  sx={{ padding: 0.5 }}
                  aria-label="share"
                  size="small"
                >
                  <Share fontSize="small" />
                </IconButton>
                <IconButton
                  sx={{ padding: 0.5 }}
                  aria-label="more"
                  size="small"
                  onClick={(event) => handleMenuOpen(event, posting.id)}
                >
                  <MoreVert fontSize="small" />
                </IconButton>
              </div>
              <Typography
                variant="subtitle1"
                sx={{ marginBottom: 1, maxWidth: `calc(100% - 96px)` }}
              >
                <span style={{ fontWeight: "bold" }}>{posting.title}</span>
                {!isMobile && (
                  <Box
                    component="span"
                    sx={{
                      display: "inline-block",
                      marginLeft: 2,
                    }}
                  >
                    <Chip
                      label={dashboardStore.statusMap[posting.status].name}
                      size="small"
                      sx={{
                        backgroundColor:
                          dashboardStore.statusMap[posting.status].color,
                        color: "#fff",
                        fontSize: "0.75rem",
                      }}
                    />
                  </Box>
                )}
              </Typography>
              {isMobile && (
                <Box
                  component="span"
                  sx={{
                    display: "inline-block",
                    marginBottom: 1,
                  }}
                >
                  <Chip
                    label={dashboardStore.statusMap[posting.status].name}
                    size="small"
                    sx={{
                      backgroundColor:
                        dashboardStore.statusMap[posting.status].color,
                      color: "#fff",
                      fontSize: "0.75rem",
                    }}
                  />
                </Box>
              )}
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                {posting.region}&nbsp;│&nbsp;{posting.type}
              </Typography>
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                {posting.description}
              </Typography>
              <Chip
                label={`Expires on ${dashboardStore.getTimeText(
                  posting.dueDate,
                  true
                )}`}
                size="small"
                sx={{
                  backgroundColor: "#D3EED2",
                  color: "#17731B",
                  fontSize: "0.75rem",
                  maxWidth: "fit-content",
                  marginTop: 1,
                }}
              />
              <Typography
                variant="body2"
                sx={{ color: "lightgray", marginTop: 1 }}
              >
                Contract ID: {posting.id}
              </Typography>
            </Card>
          ))}
        </CardContent>
      </div>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            if (selectedPostingId) {
              window.location.href = `/opportunities/opportunity/${selectedPostingId}`;
            }
            handleMenuClose();
          }}
        >
          Open
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (selectedPostingId) {
              window.open(
                `/opportunities/opportunity/${selectedPostingId}`,
                "_blank"
              );
            }
            handleMenuClose();
          }}
        >
          Open in a new tab
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default Postings;
