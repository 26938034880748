import { action, observable, makeObservable } from "mobx";

export class DashboardStore {
  statusMap = {
    0: { color: "#06BF0D", name: "Open" },
    1: { color: "#FFBB00", name: "Under Review" },
    2: { color: "#FF0000", name: "Not Awarded" },
  };

  news = [
    {
      title: "Article 1",
      description: "This is a short description of the article...",
      image:
        "https://i.pinimg.com/736x/ba/92/7f/ba927ff34cd961ce2c184d47e8ead9f6.jpg",
      publishDate: new Date("2024-07-15T10:30:00Z"),
    },
    {
      title: "Article 2",
      description: "This is a short description of the article...",
      image:
        "https://m.streetcat.wiki/images/thumb/4/48/Freshguygif.gif/300px-Freshguygif.gif",
      publishDate: new Date("2024-06-22T14:45:00Z"),
    },
    {
      title: "Article 3",
      description: "This is a short description of the article...",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKfTzRiDc-HYxu6l5_jZpSqkOsctxH-oMesA&s",
      publishDate: new Date("2024-05-05T09:00:00Z"),
    },
  ];

  bids = [
    {
      title: "Solicitation 1",
      region: "Region 1",
      type: "Contract Type",
      id: "1234567",
      status: 0,
      dueDate: new Date("2024-08-10T11:15:00Z"),
    },
    {
      title: "Solicitation 2",
      region: "Region 2",
      type: "Contract Type",
      id: "2345678",
      status: 1,
      dueDate: new Date("2024-08-25T16:30:00Z"),
    },
    {
      title: "Solicitation 3",
      region: "Region 3",
      type: "Contract Type",
      id: "3456789",
      status: 2,
      dueDate: new Date("2024-09-01T08:00:00Z"),
    },
  ];

  postings = [
    {
      title: "Example Opportunity 1",
      region: "Region 1",
      type: "Contract Type",
      description:
        "This is a description of the contract details, including contractor responsibilities and other notes relevant to the contract. This continues the contract details, including contractor responsibilities and other notes relevant to the contract.",
      dueDate: new Date("2024-08-20T12:00:00Z"),
      id: "4567890",
      status: 0,
    },
    {
      title: "Example Opportunity 2",
      region: "Region 1",
      type: "Contract Type",
      description:
        "This is a description of the contract details, including contractor responsibilities and other notes relevant to the contract. This continues the contract details, including contractor responsibilities and other notes relevant to the contract.",
      dueDate: new Date("2024-08-30T15:30:00Z"),
      id: "5678901",
      status: 0,
    },
  ];

  constructor() {
    makeObservable(this, {
      news: observable,
      bids: observable,
      postings: observable,

      getTimeText: action,
    });
  }

  getTimeText(publishDate, specific = false) {
    const now = new Date();
    const publishDateTime = new Date(publishDate);
    const diffMs = publishDateTime - now; // Difference in milliseconds

    if (specific) {
      // Return the publish date in MM/DD/YYYY, XX:XX PM format
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      return publishDateTime.toLocaleString("en-US", options);
    } else {
      if (diffMs > 0) {
        // Future date
        const diffSec = Math.floor(diffMs / 1000);
        const diffMin = Math.floor(diffSec / 60);
        const diffHr = Math.floor(diffMin / 60);
        const diffDay = Math.floor(diffHr / 24);

        if (diffDay > 0) {
          return `${diffDay} days left`;
        } else if (diffHr > 0) {
          return `${diffHr} hours left`;
        } else if (diffMin > 0) {
          return `${diffMin} minutes left`;
        } else if (diffSec > 0) {
          return `${diffSec} seconds left`;
        } else {
          return "Just now";
        }
      } else {
        // Past date
        const diffSec = Math.floor(-diffMs / 1000);
        const diffMin = Math.floor(diffSec / 60);
        const diffHr = Math.floor(diffMin / 60);
        const diffDay = Math.floor(diffHr / 24);

        if (diffDay > 0) {
          return `${diffDay} days ago`;
        } else if (diffHr > 0) {
          return `${diffHr} hours ago`;
        } else if (diffMin > 0) {
          return `${diffMin} minutes ago`;
        } else if (diffSec > 0) {
          return `${diffSec} seconds ago`;
        } else {
          return "Just now";
        }
      }
    }
  }
}

const dashboardStore = new DashboardStore();

export default dashboardStore;
