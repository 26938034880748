import React from 'react';
import { observer } from 'mobx-react';

const FeedManagement = observer(() => {
  return (
    <div>
      Feed Management Page
    </div>
  );
});

export default FeedManagement;