import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { withAuthenticator } from "@aws-amplify/ui-react";
import sidebarStore from "../store/sidebarStore";

const PageWrapper = ({
  element,
  showNavbar,
  showSidebar,
  title,
  authRequired,
}) => {
  const navbarHeight = showNavbar ? "66px" : "0px";

  useEffect(() => {
    document.body.style.backgroundColor = "#F9F9F9";
  }, []);

  const content = (
    <div>
      {showNavbar && <Navbar title={title} />}
      {showSidebar && <Sidebar startHeight={navbarHeight} />}
      <div
        style={{
          ...(showSidebar && {
            paddingLeft: sidebarStore.hover ? "210px" : "58px",
          }),
          ...(showNavbar && { paddingTop: navbarHeight }),
        }}
      >
        {element}
      </div>
    </div>
  );

  const WrappedComponent = authRequired
    ? withAuthenticator(() => content)
    : () => content;

  return <WrappedComponent />;
};

export default observer(PageWrapper);
