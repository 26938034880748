import React from 'react';
import { observer } from 'mobx-react';

const Partnership = observer(() => {
  return (
    <div>
      Partnership Page
    </div>
  );
});

export default Partnership;