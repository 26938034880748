import { action, observable, makeObservable } from "mobx";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";

class SidebarStore {
  openIndex = null;
  selectedIndex = null;
  hover = false;

  items = [
    [
      { text: "Dashboard", icon: <HomeOutlinedIcon />, link: "/dashboard" },
      {
        text: "Opportunities",
        icon: <SearchOutlinedIcon />,
        subItems: [
          { text: "Database", link: "/opportunities/database" },
          { text: "Feed Management", link: "/opportunities/feedManagement" },
        ],
      },
      { text: "Awards", icon: <StarOutlineIcon />, link: "/awards" },
      { text: "Contacts", icon: <MessageOutlinedIcon />, link: "/contacts" },
    ],
    [
      { text: "Worklist", icon: <ChecklistOutlinedIcon />, link: "/worklist" },
      {
        text: "Partnership",
        icon: <PeopleOutlineIcon />,
        link: "/partnership",
      },
    ],
  ];

  constructor() {
    makeObservable(this, {
      openIndex: observable,
      selectedIndex: observable,
      hover: observable,
      items: observable,
      setOpenIndex: action,
      setSelectedIndex: action,
      setHover: action,
    });
  }

  setOpenIndex(index) {
    this.openIndex = index;
  }

  setSelectedIndex(index) {
    this.selectedIndex = index;
  }

  setHover(hover) {
    this.hover = hover;
  }
}

const sidebarStore = new SidebarStore();

export default sidebarStore;
