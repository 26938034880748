import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Stack, Card, CardContent } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const ActionButton = ({ icon, text }) => (
  <Button
    variant="outlined"
    startIcon={icon}
    sx={{
      justifyContent: "center",
      alignItems: "center",
      padding: "8px 16px",
      textTransform: "none",
      color: "black",
      borderColor: "#e0e0e0",
      borderRadius: "5px",
      textAlign: "center",
      boxShadow: "0 2px 4px rgba(180, 180, 180, 0.5)",
    }}
    fullWidth
  >
    {text}
  </Button>
);

const Actions = observer(() => {
  return (
    <Card>
      <CardContent sx={{ padding: "24px" }}>
        <Stack spacing={2}>
          <ActionButton icon={<ShareIcon />} text="Share Opportunity" />
          <ActionButton icon={<AddIcon />} text="Add to Worklist" />
          <ActionButton icon={<ContentCopyIcon />} text="Copy Link" />
          <ActionButton icon={<VisibilityOffIcon />} text="Hide from List" />
        </Stack>
      </CardContent>
    </Card>
  );
});

export default Actions;
