import React from "react";
import { Card, CardContent, Grid, Typography, Link } from "@mui/material";
import opportunityStore from "../store/opportunityStore";
import { observer } from "mobx-react-lite";

const ContactInfo = observer(() => {
  return (
    <Card>
      <CardContent sx={{ padding: "24px" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "16px" }}
        >
          Contact Information
        </Typography>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2} alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Public Link
              </Typography>
              <Link
                href={opportunityStore.publicLink}
                variant="subtitle2"
                target="_blank"
                rel="noopener noreferrer"
              >
                {opportunityStore.publicLink}
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Contact Name
              </Typography>
              <Typography variant="subtitle2">
                {opportunityStore.contactName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Phone
              </Typography>
              <Typography variant="subtitle2">
                {opportunityStore.phone}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Email
              </Typography>
              <Typography variant="subtitle2">
                {opportunityStore.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});

export default ContactInfo;
