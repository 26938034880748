import React from 'react';
import FeedManagement from './FeedManagement';

// eslint-disable-next-line
export default {
  path: '/opportunities/feedManagement',
  title: 'Feed Management',
  element: <FeedManagement />,
  showNavbar: true,
  showSidebar: true,
  authRequired: true,
};
