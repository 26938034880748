import React from 'react';
import Worklist from './Worklist';

// eslint-disable-next-line
export default {
  path: '/worklist',
  title: 'Worklist',
  element: <Worklist />,
  showNavbar: true,
  showSidebar: true,
  authRequired: true,
};
