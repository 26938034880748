import React from "react";
import { Card, CardContent, Grid, Typography, Box, Link } from "@mui/material";
import opportunityStore from "../store/opportunityStore";
import { observer } from "mobx-react-lite";

const MainInfo = observer(() => {
  return (
    <Card>
      <CardContent sx={{ padding: "24px" }}>
        <Grid container spacing={2} alignItems="center">
          {/* Contract ID and Expiry Date */}
          <Grid item xs={6}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Contract ID: {opportunityStore.contractId}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Box
              component="span"
              sx={{
                backgroundColor: "#D3EED2",
                padding: "4px 8px",
                borderRadius: "4px",
                color: "#17731B",
              }}
            >
              Expires: {opportunityStore.expiryDate}
            </Box>
          </Grid>

          {/* Opportunity Title */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {opportunityStore.opportunityTitle}
            </Typography>
          </Grid>

          {/* Opportunity Details */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              External ID
            </Typography>
            <Typography variant="subtitle2">
              {opportunityStore.externalId}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Issuing Organization
            </Typography>
            <Link
              href={opportunityStore.issuingOrganization.url}
              variant="subtitle2"
              target="_blank"
              rel="noopener noreferrer"
            >
              {opportunityStore.issuingOrganization.text}
            </Link>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Owner Organization
            </Typography>
            <Typography variant="subtitle2">
              {opportunityStore.ownerOrganization}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Request Type
            </Typography>
            <Typography variant="subtitle2">
              {opportunityStore.requestType}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Title
            </Typography>
            <Typography variant="subtitle2">
              {opportunityStore.opportunityTitleDetail}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Agreement Type
            </Typography>
            <Typography variant="subtitle2">
              {opportunityStore.agreementType}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});

export default MainInfo;
