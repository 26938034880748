import React from 'react';
import Dashboard from './Dashboard';

// eslint-disable-next-line
export default {
  path: '/dashboard',
  title: 'Dashboard',
  element: <Dashboard />,
  showNavbar: true,
  showSidebar: true,
  authRequired: true,
};
