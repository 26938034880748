import React from "react";
import { observer } from "mobx-react-lite";
import { Card, CardContent, Stack, Button, Typography } from "@mui/material";
import opportunityStore from "../store/opportunityStore";

const DocumentButton = ({ icon, name }) => (
  <Button
    variant="outlined"
    startIcon={icon}
    sx={{
      justifyContent: "center",
      alignItems: "center",
      padding: "8px 16px",
      textTransform: "none",
      color: "black",
      borderColor: "#e0e0e0",
      borderRadius: "5px",
      textAlign: "center",
      boxShadow: "0 2px 4px rgba(180, 180, 180, 0.5)",
    }}
    fullWidth
    onClick={() => {
      // NOTE: add this later
    }}
  >
    <Typography variant="body2" sx={{ flex: 1 }}>
      {name}
    </Typography>
  </Button>
);

const Documents = observer(() => {
  return (
    <Card>
      <CardContent sx={{ padding: "24px" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "16px" }}
        >
          Documents
        </Typography>

        <Stack spacing={2}>
          {opportunityStore.documents.map((doc, index) => (
            <DocumentButton key={index} icon={doc.icon} name={doc.name} />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
});

export default Documents;
