import { action, observable, makeObservable } from "mobx";

class DatabaseStore {
  regions = ["Any"];
  availableRegions = ["Any", "CAN-EAST", "US-WEST"];

  categories = ["Any"];
  availableCategories = [
    "Any",
    "Education",
    "Technology",
    "Finance",
    "Healthcare",
  ];

  requestTypes = ["Any"];
  availableRequestTypes = ["Any", "RFx", "RFP", "RFQ", "RFI"];

  selectedDateRange = {
    startDate: null,
    endDate: null,
  };

  MAX_VALUE = 1000000000;
  value = [0, this.MAX_VALUE];

  statuses = {
    open: false,
    cancelled: false,
    expired: false,
    awarded: false,
  };

  postings = [
    {
      title: "Example Opportunity 1",
      region: "Region 1",
      type: "Contract Type",
      description:
        "This is a description of the contract details, including contractor responsibilities and other notes relevant to the contract.",
      dueDate: new Date("2024-08-20T12:00:00Z"),
      id: "4567890",
      status: 0,
    },
    {
      title: "Example Opportunity 2",
      region: "Region 1",
      type: "Contract Type",
      description:
        "This is a description of the contract details, including contractor responsibilities and other notes relevant to the contract.",
      dueDate: new Date("2024-08-30T15:30:00Z"),
      id: "5678901",
      status: 0,
    },
    {
      title: "Example Opportunity 3",
      region: "Region 2",
      type: "Contract Type",
      description:
        "This is a description of the contract details, including contractor responsibilities and other notes relevant to the contract.",
      dueDate: new Date("2024-08-30T15:30:00Z"),
      id: "5678901",
      status: 1,
    },
  ];

  statusMap = {
    0: { color: "#06BF0D", name: "Open" },
    1: { color: "#FFBB00", name: "Under Review" },
    2: { color: "#FF0000", name: "Not Awarded" },
  };

  selectedPostingId = null;
  searchTerm = "";
  filteredPostings = this.postings;

  loading = false; // New loading state

  constructor() {
    makeObservable(this, {
      regions: observable,
      categories: observable,
      requestTypes: observable,
      selectedDateRange: observable,
      value: observable,
      statuses: observable,
      availableCategories: observable,
      availableRegions: observable,
      availableRequestTypes: observable,
      postings: observable,
      selectedPostingId: observable,
      searchTerm: observable,
      filteredPostings: observable,
      addRegion: action,
      removeRegion: action,
      addCategory: action,
      removeCategory: action,
      addRequestType: action,
      removeRequestType: action,
      setStartDate: action,
      setEndDate: action,
      setValue: action,
      setRegions: action,
      setCategories: action,
      setRequestTypes: action,
      formatValue: action,
      removeLastRegion: action,
      removeLastCategory: action,
      removeLastRequestType: action,
      toggleStatus: action,
      getTimeText: action,
      setSelectedPostingId: action,
      setSearchTerm: action,
      updateFilteredPostings: action,
      loading: observable,
      setLoading: action,
    });
  }

  addRegion(region) {
    this.regions.push(region);
  }

  removeRegion(index) {
    this.regions.splice(index, 1);
  }

  addCategory(category) {
    this.categories.push(category);
  }

  removeCategory(index) {
    this.categories.splice(index, 1);
  }

  addRequestType(requestType) {
    this.requestTypes.push(requestType);
  }

  removeRequestType(index) {
    this.requestTypes.splice(index, 1);
  }

  setStartDate(date) {
    this.selectedDateRange.startDate = date;
  }

  setEndDate(date) {
    this.selectedDateRange.endDate = date;
  }

  linearToLog(value) {
    return Math.log10(value + 1);
  }

  logToLinear(value) {
    return Math.pow(10, value) - 1;
  }

  setValue(newValue) {
    this.value = [this.logToLinear(newValue[0]), this.logToLinear(newValue[1])];
  }

  setRegions(regions) {
    this.regions = regions;
  }

  setCategories(categories) {
    this.categories = categories;
  }

  setRequestTypes(requestTypes) {
    this.requestTypes = requestTypes;
  }

  getSliderValue() {
    return [this.linearToLog(this.value[0]), this.linearToLog(this.value[1])];
  }

  getSliderMin() {
    return this.linearToLog(0);
  }

  getSliderMax() {
    return this.linearToLog(this.MAX_VALUE);
  }

  getSliderStep() {
    return (this.getSliderMax() - this.getSliderMin()) / 100;
  }

  formatValue(minValue, maxValue) {
    const format = (val) => {
      const roundToSignificantDigits = (num) => {
        if (num === 0) return 0;
        const d = Math.ceil(Math.log10(num));
        const power = Math.pow(10, d - 2);
        return Math.round(num / power) * power;
      };

      const formattedValue = (val) => {
        return `$${roundToSignificantDigits(val).toLocaleString()}`;
      };

      if (minValue === maxValue) return formattedValue(minValue);
      if (maxValue === this.MAX_VALUE) return `${formattedValue(minValue)}+`;
      return `${formattedValue(minValue)} - ${formattedValue(maxValue)}`;
    };

    minValue = Math.round(minValue);
    maxValue = Math.round(maxValue);

    return format(minValue, maxValue);
  }

  removeLastRegion() {
    this.regions.pop();
  }

  removeLastCategory() {
    this.categories.pop();
  }

  removeLastRequestType() {
    this.requestTypes.pop();
  }

  toggleStatus(status) {
    if (this.statuses.hasOwnProperty(status)) {
      this.statuses[status] = !this.statuses[status];
    }
  }

  getTimeText(publishDate, specific = false) {
    const now = new Date();
    const publishDateTime = new Date(publishDate);
    const diffMs = publishDateTime - now;

    if (specific) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      return publishDateTime.toLocaleString("en-US", options);
    } else {
      if (diffMs > 0) {
        const diffSec = Math.floor(diffMs / 1000);
        const diffMin = Math.floor(diffSec / 60);
        const diffHr = Math.floor(diffMin / 60);
        const diffDay = Math.floor(diffHr / 24);

        if (diffDay > 0) {
          return `${diffDay} days left`;
        } else if (diffHr > 0) {
          return `${diffHr} hours left`;
        } else if (diffMin > 0) {
          return `${diffMin} minutes left`;
        } else if (diffSec > 0) {
          return `${diffSec} seconds left`;
        } else {
          return "Just now";
        }
      } else {
        const diffSec = Math.floor(-diffMs / 1000);
        const diffMin = Math.floor(diffSec / 60);
        const diffHr = Math.floor(diffMin / 60);
        const diffDay = Math.floor(diffHr / 24);

        if (diffDay > 0) {
          return `${diffDay} days ago`;
        } else if (diffHr > 0) {
          return `${diffHr} hours ago`;
        } else if (diffMin > 0) {
          return `${diffMin} minutes ago`;
        } else if (diffSec > 0) {
          return `${diffSec} seconds ago`;
        } else {
          return "Just now";
        }
      }
    }
  }

  setSelectedPostingId(postingId) {
    this.selectedPostingId = postingId;
  }

  setLoading(isLoading) {
    this.loading = isLoading;
  }

  setSearchTerm(term) {
    this.searchTerm = term;
    this.updateFilteredPostings();
  }

  updateFilteredPostings() {
    const term = this.searchTerm.toLowerCase();

    this.filteredPostings = this.postings.filter((posting) => {
      return (
        posting.title.toLowerCase().includes(term) ||
        posting.description.toLowerCase().includes(term)
      );
    });
  }

  // NOTE: call backend API for this later
  search(term) {
    this.setLoading(true);
    setTimeout(() => {
      this.setLoading(false);
      this.setSearchTerm(term);
    }, 500);
  }

  addPosting(posting) {
    this.postings.push(posting);
    this.updateFilteredPostings();
  }

  removePosting(index) {
    this.postings.splice(index, 1);
    this.updateFilteredPostings();
  }
}

const databaseStore = new DatabaseStore();
export default databaseStore;
