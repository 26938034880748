import React from 'react';
import { observer } from 'mobx-react';

const Worklist = observer(() => {
  return (
    <div>
      Worklist Page
    </div>
  );
});

export default Worklist;