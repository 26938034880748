import React from 'react';
import { observer } from 'mobx-react';

const Awards = observer(() => {
  return (
    <div>
      Awards Page
    </div>
  );
});

export default Awards;