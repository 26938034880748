import { action, observable, makeObservable } from "mobx";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import PngIcon from "@mui/icons-material/InsertPhoto";

export class OpportunityStore {
  // Main Info
  contractId = "CT-2024-00123";
  expiryDate = new Date(2024, 11, 31, 17, 0).toLocaleString();
  opportunityTitle = "Public Infrastructure Project";
  externalId = "EXT-987654321";
  issuingOrganization = {
    url: "https://canada.ca",
    text: "Government of Canada",
  };
  ownerOrganization = "Example Construction Co.";
  requestType = "Request for Proposal (RFP)";
  opportunityTitleDetail = "Public Infrastructure Project - Road Expansion";
  agreementType = "Public Sector Agreement (PSA)";

  // Details
  location = "Canada - Ontario - Toronto";
  deliveryPoint = ["123 Example Street", "Toronto, Ontario", "Canada A1A 1A1"];
  purchaseType = "Duration: 3 years | Option: 2 year additional";
  description =
    "This project involves the expansion of existing road infrastructure to accommodate increased traffic flow.";

  // Dates
  respondBy = new Date(2024, 11, 31, 17, 0).toLocaleString();
  lastModified = new Date(2024, 11, 31, 17, 0).toLocaleString();

  // Contact Info
  publicLink = "https://admini.ca/contacts";
  contactName = "John Doe";
  phone = "123-456-7890";
  email = "abcd@example.com";

  // Audit
  solicitationStatus = "Public";
  date = new Date(2024, 11, 31, 17, 0).toLocaleString();

  // Documents
  documents = [
    { name: "Contract_Summary.png", icon: <PngIcon /> },
    { name: "Request_for_Proposal.docx", icon: <FileIcon /> },
    { name: "Addendum_1.pdf", icon: <PdfIcon /> },
    { name: "Addendum_2.pdf", icon: <PdfIcon /> },
  ];

  // Categories
  // Categories
  adminiCategories = [
    {
      id: "10324985",
      name: "Office Supplies",
      subcategories: [
        {
          id: "23874519",
          name: "Paper",
          subcategories: [
            {
              id: "56293048",
              name: "A4 Paper",
              subcategories: [],
            },
            {
              id: "95718234",
              name: "A3 Paper",
              subcategories: [],
            },
          ],
        },
        {
          id: "48392057",
          name: "Pens",
          subcategories: [],
        },
      ],
    },
    {
      id: "74958210",
      name: "Electronics",
      subcategories: [
        {
          id: "59283746",
          name: "Computers",
          subcategories: [],
        },
        {
          id: "23948571",
          name: "Printers",
          subcategories: [
            {
              id: "48592034",
              name: "Laser Printers",
              subcategories: [],
            },
            {
              id: "78394205",
              name: "Inkjet Printers",
              subcategories: [],
            },
          ],
        },
      ],
    },
  ];

  unspscCategories = [
    {
      id: "18763492",
      name: "Healthcare",
      subcategories: [
        {
          id: "29584716",
          name: "Medical Equipment",
          subcategories: [
            {
              id: "58273491",
              name: "Diagnostic Equipment",
              subcategories: [],
            },
            {
              id: "72659184",
              name: "Surgical Instruments",
              subcategories: [],
            },
          ],
        },
        {
          id: "18472639",
          name: "Pharmaceuticals",
          subcategories: [],
        },
      ],
    },
    {
      id: "47392018",
      name: "Construction",
      subcategories: [
        {
          id: "92837465",
          name: "Building Materials",
          subcategories: [
            {
              id: "56273894",
              name: "Concrete",
              subcategories: [],
            },
            {
              id: "29184756",
              name: "Steel",
              subcategories: [],
            },
          ],
        },
        {
          id: "38475912",
          name: "Tools",
          subcategories: [],
        },
      ],
    },
  ];

  constructor() {
    makeObservable(this, {
      contractId: observable,
      expiryDate: observable,
      opportunityTitle: observable,
      externalId: observable,
      issuingOrganization: observable,
      ownerOrganization: observable,
      requestType: observable,
      opportunityTitleDetail: observable,
      agreementType: observable,
      location: observable,
      deliveryPoint: observable,
      purchaseType: observable,
      description: observable,
      respondBy: observable,
      lastModified: observable,
      publicLink: observable,
      contactName: observable,
      phone: observable,
      email: observable,
      solicitationStatus: observable,
      date: observable,

      setContractId: action,
    });
  }

  setContractId = (id) => {
    this.contractId = id;
  };
}

const opportunityStore = new OpportunityStore();

export default opportunityStore;
