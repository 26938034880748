import React from "react";
import { Card, CardContent, CardMedia, Typography, Link } from "@mui/material";
import dashboardStore from "../store/dashboardStore";

const News = () => {
  return (
    <Card
      sx={{
        height: "50vh",
        backgroundColor: "#FEFEFE",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <CardContent
        sx={{
          paddingTop: 2,
          flex: "none",
          borderBottom: "1px solid #ddd",
          backgroundColor: "#fff",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" align="left" sx={{ fontWeight: "bold" }}>
            News/Updates
          </Typography>
          <Link
            href="#"
            sx={{
              fontSize: "0.875rem",
              fontWeight: "bold",
              textDecoration: "none",
              color: "#1976d2",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            View All
          </Link>
        </div>
      </CardContent>
      <div
        style={{
          flex: 1,
          overflowY: "scroll",
          padding: "8px",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <CardContent
          sx={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          {dashboardStore.news.map((article, index) => (
            <Card
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: 2,
                alignItems: "center",
                position: "relative",
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: 50,
                  height: 50,
                  marginLeft: 1,
                  borderRadius: 2,
                }}
                image={article.image}
                alt={article.title}
              />
              <CardContent
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  padding: 1,
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {article.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {article.description}
                  <Link
                    href="#"
                    sx={{
                      fontWeight: "bold",
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                      zIndex: 1,
                      color: "black",
                    }}
                  >
                    Read More
                  </Link>
                </Typography>
              </CardContent>
              <CardContent
                sx={{
                  position: "absolute",
                  top: 4,
                  right: 4,
                  padding: 1,
                }}
              >
                <Typography variant="body2">
                  {dashboardStore.getTimeText(article.publishDate)}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </CardContent>
      </div>
    </Card>
  );
};

export default News;
