import React from "react";
import { observer } from "mobx-react-lite";
import {
  Card,
  CardContent,
  Typography,
  Stack,
  CardActionArea,
} from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd"; // File Add icon

const Workspace = observer(() => {
  return (
    <Card>
      <CardContent sx={{ padding: "24px" }}>
        <Stack spacing={2}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", textAlign: "left" }}
          >
            Workspace
          </Typography>
          <CardActionArea
            sx={{
              width: "100%",
              height: 200,
              border: "1px solid #e0e0e0",
              borderRadius: "8px",
              backgroundColor: "#f5f5f5",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              // NOTE: Handle click event here (for now, it does nothing)
            }}
          >
            <Stack spacing={1} alignItems="center">
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                Create a Workspace
              </Typography>
              <NoteAddIcon fontSize="medium" />
            </Stack>
          </CardActionArea>
        </Stack>
      </CardContent>
    </Card>
  );
});

export default Workspace;
