import React from "react";

import Opportunity from "./Opportunity";

// eslint-disable-next-line
export default {
  path: "/opportunities/opportunity/:id",
  title: "Opportunity",
  element: <Opportunity />,
  showNavbar: true,
  showSidebar: true,
  authRequired: true,
};
