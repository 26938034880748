import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import Navigation from "./components/Navigation";
import MainInfo from "./components/MainInfo";
import opportunityStore from "./store/opportunityStore";
import Actions from "./components/Actions";
import Details from "./components/Details";
import Dates from "./components/Dates";
import ContactInfo from "./components/ContactInfo";
import Audit from "./components/Audit";
import Workspace from "./components/Workspace";
import Documents from "./components/Documents";
import Categories from "./components/Categories";

const Opportunity = observer(() => {
  const { id } = useParams();

  useEffect(() => {
    // NOTE: Make this fetch from backend later. If not found, display Error on page.
    opportunityStore.setContractId(id);
  }, [id]);

  return (
    <Container>
      {" "}
      <Navigation />
      <Grid container spacing={2} sx={{ marginTop: 0, marginBottom: 2 }}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MainInfo />
            </Grid>
            <Grid item xs={12}>
              <Details />
            </Grid>
            <Grid item xs={12}>
              <Dates />
            </Grid>
            <Grid item xs={12}>
              <ContactInfo />
            </Grid>
            <Grid item xs={12}>
              <Audit />
            </Grid>
            <Grid item xs={12}>
              <Documents />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Actions />
            </Grid>
            <Grid item xs={12}>
              <Workspace />
            </Grid>
            <Grid item xs={12}>
              <Categories />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
});

export default Opportunity;
