import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Card, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Navigation = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const goBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/opportunities/database");
    }
  };

  // NOTE: create an API under ./api for these later
  const navigatePrev = () => {
    const newId = Math.floor(10000000 + Math.random() * 90000000).toString();
    navigate(`/opportunities/opportunity/${newId}`);
  };

  const navigateNext = () => {
    const newId = Math.floor(10000000 + Math.random() * 90000000).toString();
    navigate(`/opportunities/opportunity/${newId}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      <Button
        variant="text"
        startIcon={<ArrowBackIcon />}
        onClick={goBack}
        sx={{ color: "black", fontSize: "0.875rem", textTransform: "none" }}
      >
        {isMobile ? "Back" : "Back to Previous Page"}
      </Button>

      <Box sx={{ display: "flex" }}>
        <Card sx={{ marginRight: 1, minWidth: 100 }}>
          <Button
            variant="text"
            startIcon={<ArrowBackIosIcon />}
            onClick={navigatePrev}
            sx={{ color: "black", fontSize: "0.875rem", textTransform: "none" }}
            fullWidth
          >
            Prev
          </Button>
        </Card>
        <Card sx={{ minWidth: 100 }}>
          <Button
            variant="text"
            endIcon={<ArrowForwardIosIcon />}
            onClick={navigateNext}
            sx={{ color: "black", fontSize: "0.875rem", textTransform: "none" }}
            fullWidth
          >
            Next
          </Button>
        </Card>
      </Box>
    </Box>
  );
};

export default Navigation;
