import React from "react";
import { observer } from "mobx-react";
import { Grid, Box, Divider } from "@mui/material";
import SearchSettings from "./components/SearchSettings";
import SearchResults from "./components/SearchResults";

const Database = observer(() => {
  return (
    <Grid container>
      <Grid item xs={4}>
        <Box
          sx={{
            height: "calc(100vh - 66px)",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ overflowY: "auto" }}>
            <SearchSettings />
          </Box>
          <Divider orientation="vertical" flexItem />
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Box
          sx={{
            height: "calc(100vh - 66px)",
            display: "flex",
            flexDirection: "row",
            overflowY: "auto",
          }}
        >
          <SearchResults />
        </Box>
      </Grid>
    </Grid>
  );
});

export default Database;
