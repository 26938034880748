import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import opportunityStore from "../store/opportunityStore";
import { observer } from "mobx-react-lite";

const Details = observer(() => {
  return (
    <Card>
      <CardContent sx={{ padding: "24px" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "16px" }}
        >
          Details
        </Typography>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2} alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Location
              </Typography>
              <Typography variant="subtitle2">
                {opportunityStore.location}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", marginTop: "16px" }}
              >
                Purchase Type
              </Typography>
              <Typography variant="subtitle2">
                {opportunityStore.purchaseType}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Delivery Point
              </Typography>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {opportunityStore.deliveryPoint.map((line, index) => (
                  <Typography key={index} variant="subtitle2">
                    {line}
                  </Typography>
                ))}
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Description
              </Typography>
              <Typography variant="subtitle2">
                {opportunityStore.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});

export default Details;
