import React from 'react';
import Database from './Database';

// eslint-disable-next-line
export default {
  path: '/opportunities/database',
  title: 'Database',
  element: <Database />,
  showNavbar: true,
  showSidebar: true,
  authRequired: true,
};
