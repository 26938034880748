import React from 'react';
import Awards from './Awards';

// eslint-disable-next-line
export default {
  path: '/awards',
  title: 'Awards',
  element: <Awards />,
  showNavbar: true,
  showSidebar: true,
  authRequired: true,
};
