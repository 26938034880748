/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { ChevronDown, CheckCircle, Users, Search, FileText, Building, Menu, X, XCircle } from 'lucide-react'
import adminiLogo from "./components/adminiLogo.svg"
import Database from "./components/Database.svg"
import Opportunity from "./components/Opportunity.svg"
import Partnerships from "./components/Partnerships.svg"

export default function LandingPage() {
  const [activeTab, setActiveTab] = useState(0)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  }

  const stagger = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  }

  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'smooth';
  }, []);

  return (
    <div className="min-h-screen bg-sky-50 text-sky-900">
      {/* Header */}
      <header className="bg-white shadow-md fixed top-0 left-0 right-0 z-50">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center space-x-2">
          <img src={adminiLogo} alt="Admini Logo" />
          </div>
          <nav className="hidden md:block">
            <ul className="flex space-x-6">
              <li><a href="#features" className="hover:text-sky-600 transition-colors">Features</a></li>
              <li><a href="#how-it-works" className="hover:text-sky-600 transition-colors">How It Works</a></li>
              <li><a href="#pricing" className="hover:text-sky-600 transition-colors">Pricing</a></li>
              <li><a href="#faq" className="hover:text-sky-600 transition-colors">FAQ</a></li>
            </ul>
          </nav>
          <button onClick={() => window.location.href = './dashboard'} className="hidden md:block bg-sky-600 text-white px-4 py-2 rounded hover:bg-sky-700 transition-colors">
            Get Started
          </button>
          <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>
        {isMenuOpen && (
          <div className="md:hidden bg-white py-4">
            <nav className="container mx-auto px-4">
              <ul className="space-y-2">
                <li><a href="#features" className="block py-2 hover:text-sky-600 transition-colors" onClick={() => setIsMenuOpen(false)}>Features</a></li>
                <li><a href="#how-it-works" className="block py-2 hover:text-sky-600 transition-colors" onClick={() => setIsMenuOpen(false)}>How It Works</a></li>
                <li><a href="#pricing" className="block py-2 hover:text-sky-600 transition-colors" onClick={() => setIsMenuOpen(false)}>Pricing</a></li>
                <li><a href="#faq" className="block py-2 hover:text-sky-600 transition-colors" onClick={() => setIsMenuOpen(false)}>FAQ</a></li>
              </ul>
              <button onClick={() => window.location.href = './dashboard'} className="mt-4 w-full bg-sky-600 text-white px-4 py-2 rounded hover:bg-sky-700 transition-colors">
                Get Started
              </button>
            </nav>
          </div>
        )}
      </header>

      {/* Hero Section */}
      <section className="bg-gradient-to-b from-sky-100 to-white min-h-screen flex items-center justify-center pt-16">
        <div className="container mx-auto px-4">
          <motion.div
            className="text-center"
            initial="hidden"
            animate="visible"
            variants={stagger}
          >
            <motion.h1 variants={fadeIn} className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6">
              Connect. Collaborate. Contract.
            </motion.h1>
            <motion.p variants={fadeIn} className="text-lg md:text-xl mb-8 max-w-2xl mx-auto">
              See how Admini can help your business win the contracts you need.
            </motion.p>
            <motion.div variants={fadeIn}>
              <button onClick={() => window.location.href = './dashboard'} className="bg-sky-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-sky-700 transition-colors">
                Start Your Journey
              </button>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-20">
        <div className="container mx-auto px-4">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-3xl font-bold text-center mb-12"
          >
            Powerful Features for Efficient Contracting
          </motion.h2>
          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={stagger}
          >
            {[
              { icon: Search, title: "AI Contract Searching", description: "Find contracts using our advanced vector search database", image: Database},
              { icon: FileText, title: "Centralized Contract Management", description: "Access millions of active contracts in one place", image: Opportunity},
              { icon: Users, title: "Collaborative Workspace", description: "Work seamlessly with subcontractors", image: Partnerships},
            ].map((feature, index) => (
              <motion.div
                key={index}
                variants={fadeIn}
                className="bg-white p-6 rounded-lg shadow-md flex flex-col justify-between h-full"
              >
                <div>
                  <feature.icon className="h-12 w-12 text-sky-600 mb-4" />
                  <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                  <p className="text-sky-700">{feature.description}</p>
                </div>
                <img
                  src={feature.image}
                  alt={`${feature.title} image`}
                  className="mt-4 self-end"
                />
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>


      {/* Trusted Companies Section */}
      <section className="bg-sky-100 py-20">
        <div className="container mx-auto px-4">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-3xl font-bold text-center mb-12"
          >
            Collaborating With Trusted Companies
          </motion.h2>
          <motion.div
            className="flex flex-wrap justify-center items-center gap-8"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={stagger}
          >
            <motion.div key={0} variants={fadeIn}>
              <img
                src={`https://1000logos.net/wp-content/uploads/2021/12/AECOM-logo.png`}
                alt={`AECOM logo`}
                className="opacity-50 hover:opacity-100 transition-opacity"
                style={{ maxHeight: '100px', width: 'auto' }}
                />
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* How It Works Section */}
      <section id="how-it-works" className="bg-sky-50 py-20">
        <div className="container mx-auto px-4">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-3xl font-bold text-center mb-12"
          >
            How Admini Works
          </motion.h2>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8">
            {['Connect', 'Collaborate', 'Contract'].map((step, index) => (
              <motion.div
                key={index}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={fadeIn}
                className="bg-white p-6 rounded-lg shadow-md text-center w-full md:w-1/3"
              >
                <div className="bg-sky-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                  <span className="text-2xl font-bold text-sky-600">{index + 1}</span>
                </div>
                <h3 className="text-xl font-semibold mb-2">{step}</h3>
                <p className="text-sky-700">
                  {index === 0 && 'Connect with hundreds of vendors on our site. Sharing a project opportunity, new contract, or RFx is as easy as clicking a button.'}
                  {index === 1 && 'Collaborate with anyone throughout the vertical supply chain. Send an Admini workspace to work on contracts online, with traceable work tools that allow for 80% faster workflows.'}
                  {index === 2 && 'Access millions of contracts. We found every contract available in Canada so you don\'t have to.'}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="py-20">
        <div className="container mx-auto px-4">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-3xl font-bold text-center mb-12"
          >
            Simple, Transparent Pricing
          </motion.h2>
          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={stagger}
          >
            {[
              { name: "Basic", price: "$99", features: ["Unlimited Contract Access", "Access to Historical Analytics", "Basic Collaboration"], excluded: ["Full-Suite Collaborative Tools", "Private Feed Access", "Post Secondary Contract Opportunities", "Automatic Contract Update Notifications"] },
              { name: "Pro", price: "$299", features: ["Unlimited Contract Access", "Access to Historical Analytics", "Basic Collaboration", "Full-Suite Collaborative Tools", "Private Feed Access"], excluded: [ "Post Secondary Contract Opportunities", "Automatic Contract Update Notifications"] },
              { name: "Enterprise", price: "Custom", features: ["Unlimited Contract Access", "Access to Historical Analytics", "Basic Collaboration", "Full-Suite Collaborative Tools", "Private Feed Access", "Post Secondary Contract Opportunities", "Automatic Contract Update Notifications"], excluded: []},
            ].map((plan, index) => (
              <motion.div key={index} variants={fadeIn} className="bg-white p-6 rounded-lg shadow-md text-center">
                <h3 className="text-2xl font-bold mb-4">{plan.name}</h3>
                <p className="text-4xl font-bold text-sky-600 mb-6">{plan.price}<span className="text-base font-normal text-sky-700">{plan.price !== "Custom" && "/month"}</span></p>
                <ul className="mb-6 space-y-2">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-green-600 mr-2" />
                      <span>{feature}</span>
                    </li>
                  ))}
                  {plan.excluded.map((feature, i) => (
                    <li key={i} className="flex items-center">
                      <XCircle className="h-5 w-5 text-red-600 mr-2" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <button className="bg-sky-600 text-white px-6 py-2 rounded-full hover:bg-sky-700 transition-colors">
                  Choose Plan
                </button>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="bg-sky-50 py-20">
        <div className="container mx-auto px-4">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-3xl font-bold text-center mb-12"
          >
            Frequently Asked Questions
          </motion.h2>
          <motion.div
            className="space-y-4 max-w-3xl mx-auto"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={stagger}
          >
            {[
              { q: "How secure is Admini?", a: "Admini uses state-of-the-art encryption and security measures via AWS to protect your data." },
              { q: "Can I integrate Admini with other tools?", a: "Yes, Admini offers various integrations with popular project management and accounting software." },
              { q: "Is there a free trial available?", a: "We offer a 14-day free trial for all our plans. No credit card required." },
              { q: "How does the collaboration feature work?", a: "Our collaboration tools allow real-time editing, commenting, and approval workflows for contracts." },
            ].map((faq, index) => (
              <motion.div key={index} variants={fadeIn} className="bg-white p-6 rounded-lg shadow-md">
                <button
                  className="flex justify-between items-center w-full text-left"
                  onClick={() => setActiveTab(activeTab === index ? -1 : index)}
                >
                  <span className="font-semibold">{faq.q}</span>
                  <ChevronDown className={`h-5 w-5 transform transition-transform ${activeTab === index ? 'rotate-180' : ''}`} />
                </button>
                {activeTab === index && (
                  <p className="mt-4 text-sky-700">{faq.a}</p>
                )}
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-sky-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-3xl font-bold mb-6"
          >
            Ready to Streamline Your Contracting Process?
          </motion.h2>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="mb-8 max-w-2xl mx-auto"
          >
            Join a variety of satisfied companies and experience the power of centralized contract management and collaboration.
          </motion.p>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
          >
            <button onClick={() => window.location.href = './dashboard'} className="bg-white text-sky-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-sky-100 transition-colors">
              Get Started Now
            </button>
          </motion.div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-sky-800 text-sky-100 py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div>
              <h3 className="text-xl font-semibold mb-4">Admini</h3>
              <p>Connecting government contracts and subcontractors efficiently.</p>
            </div>
            <div>
              <h4 className="font-semibold mb-4">Quick Links</h4>
              <ul className="space-y-2">
                <li><a href="#features" className="hover:text-white transition-colors">Features</a></li>
                <li><a href="#how-it-works" className="hover:text-white transition-colors">How it Works</a></li>
                <li><a href="#pricing" className="hover:text-white transition-colors">Pricing</a></li>
                <li><a href="#faq" className="hover:text-white transition-colors">FAQ</a></li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-white transition-colors">Privacy Policy</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Terms of Service</a></li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold mb-4">Connect</h4>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-white transition-colors">Contact Us</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Support</a></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-sky-700 text-center">
            <p>&copy; {new Date().getFullYear()} Admini. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  )
}